<template>
  <div>
    <default-app-bar>
      <template v-slot:title>
        <div class="text-h3 mr-2">Reporte de Rentabilidad</div>
      </template>
    </default-app-bar>
    <v-card v-if="form">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <ng-date v-bind:form="form" :size="12" v-bind:options="config.fecha_desembolso"></ng-date>
          </v-col>
          <!--v-col cols="12" sm="6" class="form-col"  > 
                <ng-select-multi  v-bind:form="form" size="12"  v-bind:options="config.bus_producto_id"></ng-select-multi>
            </v-col-->

          <v-col cols="12" md="12" class="text-right my-auto">
            <v-btn v-on:click="search()" block color="primary">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div :key="renderKey" class="pa-4">
      <!--div class="text-right">
              <v-btn size="small" icon v-on:click="excel()"><v-icon>mdi-file-excel-box</v-icon></v-btn>
            </div-->
      <v-list v-if="items">
        <div v-for="item in items" :key="item.id">
          <v-list-item>
            <v-list-item-content class="item-credito">
              <div style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 2px;
                ">

                <div class="kn-item">
                  <div class="icon"><v-icon color="primary">mdi-calendar</v-icon></div>
                  <div class="content mt-2">
                    <span>Fecha de reporte</span>
                    <span class="font-weight-bold">{{ $filters.date(item.fecha_generacion) }}</span>
                  </div>
                </div>

              </div>
              <!--div
                style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 2px;
                "
              >
                <span class="b1">Cliente:</span><span> {{ item.cliente }}</span>
              </div-->

            </v-list-item-content>
            <v-list-item-action>
              <v-btn small icon dark @click="showInfo(item)" color="primary"><v-icon>mdi-menu-right</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { NgForm, handler } from "@/components/gui/NgForm";


export default {
  name: "Desembolsos",
  data() {
    return {
      form: null, //{ bus_person_cliente_id: null, fecha_desembolso: { from: null, to: null } },
      config: {
        //bus_person_cliente_id: { label: 'Cliente', dense: false, name: 'bus_person_cliente_id', url: 'api/v1/admin/person/search' },
        fecha_desembolso: { label: "Fecha", name: "fecha_desembolso" },
        /*bus_medio_pago_id: { label: 'Medio de desembolso', name: 'bus_medio_pago_id', url: 'api/default/bus_medio_pago/search' },
            bus_convenio_admin_cartera_id: { label: 'Origen de Fondos', name: 'bus_convenio_admin_cartera_id', url: 'api/admin/person/search-inver' },*/
      },
      renderKey: 1,
      info: null,
      items: [],
    };
  },
  mounted() {
    //console.log(this.pageConfg)
    const form = new NgForm(this.config, this);
    this.form = new Proxy(form, handler);
    //this.loadItem();
    this.search();
  },
  methods: {
    getFormValue() {
      const value = this.form.value;
      //value['_conv_per_id'] = this.user.personId;
      return value;
    },

    toogleDrawer() {
      this.$store.commit("toggle");
    },
    search() {
      this.$http
        .post("api/admin/reporte-rentabilidad/list", {
          data: this.getFormValue(),
        })
        .then((res) => {
          if (res.success) {
            this.items = res.items;
          }

          //this.tmpl = res.data.html;
          this.renderKey++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showInfo(item) {
      this.$router.push('/admin/report/rentabilidad-detail/' + item.id);
    },
    serialize_query(params) {
      //return new URLSearchParams(params).toString()

      const value = {};

      for (const i in params) {
        if (Array.isArray(params[i])) {
          value[i] = params[i].map((item) => {
            if (item.id) {
              return { id: item.id };
            }
            return item;
          });
        } else {
          if (params[i] && params[i].id) {
            value[i] = { id: params[i].id };
          } else {
            value[i] = params[i];
          }
        }
      }

      return "params=" + JSON.stringify(value);
    },
  },
};
</script>
